<template>
    <button class="btn" type="button">
      <span class="btn-title">
        <slot></slot>
      </span>
    </button>
  </template>
  
  <style scoped>
  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 39px;
    gap: 10px;
    width: 100%;
    font-weight: 500;
    font-size: 0.875rem;
    text-align: center;
    color: #28224B;
    border: 1px solid #28224b;

    background-color: inherit;
    border-radius: 10px;
  }
  
  .btn:hover {
    border: 1px solid #23B990;
    color: #FFFFFF;
    background: #23B990;
  }
  
  @media screen and (max-width: 478px) {
    .btn {
      padding: 14px 16px;
    }
  }
  
  </style>
  