import axios from "axios"
import { ref } from "vue"
import { useStore } from '../store/useStore'

export function useDashboard() {
  const sessionsComplete = ref(0)
  const nextSessions = ref([])
  const openPlans = ref([])
  const pendingPlans = ref([])
  const failedPlans = ref([])
  const stoppedPlans = ref([])
  const completedPlans = ref([])
  const newMessagesCount = ref(0)
  const conversations = ref([])
  const store = useStore()

  const fetching = async () => {
      await axios.get(process.env.VUE_APP_API_URL + "user/dashboard")
      .then(function (response){
        sessionsComplete.value = response.data.data.sessions_complete
        nextSessions.value = response.data.data.next_sessions
        openPlans.value = response.data.data.plans.open
        pendingPlans.value = response.data.data.plans.pending
        failedPlans.value = response.data.data.plans.failed
        stoppedPlans.value = response.data.data.plans.stopped
        completedPlans.value = response.data.data.plans.completed
        newMessagesCount.value = response.data.data.new_messages_count
        if(response.data.data.conversations){
          conversations.value = response.data.data.conversations
        }else{
          conversations.value = []
        }

        if(response.data.data.new_password_required){
          store.newPasswordRequired = true
        }else{
          store.newPasswordRequired = false
        }
      })
      .catch(function (error){
        console.log(error)
      })
  }

  return { sessionsComplete, nextSessions, openPlans, pendingPlans, failedPlans, completedPlans, stoppedPlans, conversations, newMessagesCount, fetching }
}