import { defineStore } from "pinia"
import { ref } from 'vue'

export const usePlanStore = defineStore('plan', () => {
  const currentPlan = ref({
    id: null,
    name: ''
  })

  const psfs = ref()

  const currentSession = ref({
    number: null,
    week: null,
    week_day: null,
    hasNextSessionInCurrentWeek: null,
    hasNextSessionInNextWeek: null,
    exercises: []
  })

  function setCurrentPlan(id, name){
  }

  function setPsfs(questions){
    if(questions && Array.isArray(questions)){
      psfs.value = questions
    }
  }

  return { currentPlan, currentSession, psfs, setPsfs }
})