<template>
  <div>
    <div class="sidebar">
      <div class="logo-details">
        <a href="https://www.zoomphysio.co.uk/" target="_blank">
          <img src="../assets/img/zp-logo.svg" />
        </a>
      </div>
      <div class="logo-ico">
        <a href="https://www.zoomphysio.co.uk/" target="_blank">
          <img src="../assets/img/logo.svg" />
        </a>
      </div>

      <ul class="nav-list">

        <li
          v-if="store.isAuth"
          class="sidebar__btn"
        >
          <router-link to="/">
            <img src="../assets/img/icons/house_b.svg" />
            <span>Dashboard</span>
          </router-link>
          <span class="sb-tooltip">Dashboard</span>
        </li>
        <li v-else class="sidebar__btn">
          <router-link to="/explore-plans">
            <img src="../assets/img/icons/house_b.svg" />
            <span>Dashboard</span>
          </router-link>
          <span class="sb-tooltip">Dashboard</span>
        </li>

        <li
          v-if="store.isAuth"
          class="sidebar__btn"
        >
          <router-link :to="{ name: 'profile' }">
            <img src="../assets/img/icons/user_gear.svg" />
            <span>Profile</span>
          </router-link>
          <span class="sb-tooltip">Profile</span>
        </li>

        <li
          v-if="store.isAuth"
          class="sidebar__btn"
        >
          <router-link :to="{ name: 'messages' }">
            <img src="../assets/img/icons/chat_dots.svg" />
            <span>Messages</span>
          </router-link>
          <span class="sb-tooltip">Messages</span>
          <div class="messages-count_wrapper">
            <CircleCounter
              v-if="showMessageCounter"
              :counter=store.newMessagesCount
            />
          </div>
        </li>

        <li
          v-if="store.isAuth"
          class="sidebar__btn"
        >
          <router-link :to="{ name: 'settings' }">
            <img src="../assets/img/icons/wrench.svg" />
            <span>Settings</span>
          </router-link>
          <span class="sb-tooltip">Settings</span>
        </li>

        <li class="sidebar__btn">
          <router-link :to="{ name: 'contactUs'}">
            <img src="../assets/img/icons/support.svg" />
            <span>Support</span>
          </router-link>
          <span class="sb-tooltip">Support</span>
        </li>

        <li
          v-if="store.isAuth"
          class="sidebar__btn"
        >
          <router-link :to="{ name: 'logout' }">
            <img src="../assets/img/icons/sign_out.svg" />
            <span>Logout</span>
          </router-link>
          <span class="sb-tooltip">Logout</span>
        </li>

        <li
          v-if="!store.isAuth"
          class="sidebar__btn"
        >
          <router-link :to="{ name: 'login' }">
            <img src="../assets/img/icons/sign_in.svg" />
            <span>Login</span>
          </router-link>
          <span class="sb-tooltip">Login</span>
        </li>
      </ul>

      <TransparentButton
        class="btn-new-plan"
        @click="$router.push('/explore-plans')"
      >Request a new plan</TransparentButton>
      <TransparentButton
        class="btn-new-plan-sm"
        @click="$router.push('/explore-plans')"
      >New plan</TransparentButton>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from '../store/useStore'
import TransparentButton from "./UI/TransparentButton"
import CircleCounter from './UI/CircleCounter.vue'

const store = useStore()

const showMessageCounter = computed(() => store.newMessagesCount > 0)

</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 376px;
  align-items: center;
  padding: 80px 75px;
  background: rgb(172, 173, 221, 0.09);
  transition: all 0.5s ease;
}

.nav-list {
  margin: 80px 0;
}

.sidebar li{
  position: relative;
  margin: 8px 0;
  list-style: none;
}

.sidebar__btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid rgba(172, 173, 221, 0.2);
}

.sidebar__btn:last-child {
  border-bottom: 0;
}

.sidebar__btn a {
  display: flex;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
  color: #28224B;
  cursor: pointer;
}

.sidebar__btn a.router-link-exact-active {
  color: #229F7D;
}

.nav-list a div {
  height: 20px;
  width: 20px;
}

.sidebar__btn a span {
  padding-left: 20px;
}

.logo-details {
  display:flex;
  height: 40px;
}
.logo-details a{
  display:flex;
  width: 100%;
}
.logo-ico {
  display: none;
}
.logo-ico a{
  width: 100%;
  display:flex;
}

.sidebar__btn .sb-tooltip{
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
  display: none;
}

.sidebar__btn:hover .sb-tooltip{
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.btn-new-plan-sm {
  display: none;
}

.messages-count_wrapper {
  margin-left: auto;
}

@media screen and (max-width: 890px) {
  .sidebar {
    width: 76px;
    padding: 20px 12px;
  }

  .sidebar__btn .sb-tooltip{
    display: block;
  }

  .sidebar__btn a span {
    display: none;
  }

  .nav-list {
    margin: 40px 0;
  }

  .sidebar__btn {
    padding: 16px 16px;
  }

  .logo-details {
    display: none;
  }

  .logo-ico, .logo-ico a {
    display: flex;
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;
  }
  
  .sidebar .btn {
    padding: 10px 5px;
  }

  .btn-new-plan {
    font-weight: 400;
    font-size: 0.6rem;
  }

  .messages-count_wrapper {
    margin-left: -7px;
    margin-top: -20px;
  }
}

@media screen and (max-width: 478px) {
  .sidebar {
    width: 46px;
    padding: 10px 4px;
  }

  .logo-ico, .logo-ico a {
    display: flex;
    width: 36px;
    height: 36px;
    margin: 0;
    padding: 0;
  }
  
  .sidebar__btn {
    padding: 6px 8px;
  }

  .btn-new-plan {
    display: none;
  }

  .btn-new-plan-sm {
    display: flex;
    font-weight: 400;
    font-size: 0.6rem;
  }

  .sidebar li{
    margin: 0;
    padding: 10px;
  }

  .sidebar__btn:hover .sb-tooltip{
    opacity: 0;
  }

  .messages-count_wrapper {
    margin-left: -10px;
    margin-top: -15px;
  }
}
</style>