<template>
  <div v-if="plans.length > 0" class="treatments__wrapper">
    <div class="treatments__wrapper_">
      <div class="treatments_content">
        <div class="treatments-title">
          {{ title }}
          <span>({{ plansCount }})</span>
        </div>
        <div class="treatment-list">
          <PlanItem
            v-for="plan in plans"
            :plan="plan"
            :key="plan.id"
            @click="$emit('planClick', plan)"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import PlanItem from './PlanItem.vue';
import { computed } from 'vue';

const props = defineProps({
  title: String,
  plans: {
    type: Array,
    required: true
  }
})

defineEmits(['planClick'])

const plansCount = computed(() => props.plans.length)

</script>

<style scoped>
.treatments__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;
  width: 100%;
}

.treatments__wrapper_ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
}

.treatments_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
}

.treatments-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  font-weight: 600;
  font-size: 1.25rem;
  color: #28224B;
}

.treatments-title span{
  font-weight: 400;
}

.treatment-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
}

@media screen and (max-width: 478px) {
  .treatments-title {
    font-size: 1rem;
  }
}
</style>