<template>
  <div>
    <Dashboard v-if="store.isAuth" />
    <Intro v-else />
  </div>
</template>

<script setup>
import Intro from "../components/Intro.vue"
import Dashboard from "../components/Dashboard.vue"
import { useStore } from "../store/useStore"

const store = useStore()
</script>
