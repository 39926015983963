<template>
  <div class="header_wrapper">

    <div
      v-if="props.showBackLink"
      class="m-title-content"
    >
      <div
          class="back_link"
          @click="backLinkClick"
        ><img src="../assets/img/icons/caret_left.svg" /></div>
    </div>
    <div class="wrapper__title">
      <div class="title-content">
        <div :class="titleClass">
          <div
            v-if="props.showBackLink"
            class="back_link"
            @click="backLinkClick"
          ><img src="../assets/img/icons/caret_left.svg" /></div>

          {{ title }}

          <Tooltip
            v-if="showInfoIco"
            :tooltip-text=tooltipText
          >
            <div
              class="btn-info"
              @click="$emit('infoIcoClick')"
            >
              <img src="../assets/img/icons/info.svg" />
            </div>
          </Tooltip>

        </div>
        <div :class="subtitleClass">{{ subtitle }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from '../store/useStore';
import { useAuthStore } from '../store/useAuthStore';
import { useRouter } from 'vue-router'
import Tooltip from './UI/Tooltip.vue';

const store = useStore()
const authStore = useAuthStore()

const props = defineProps({
  title: String,
  subtitle: String,
  titleReverseSize: {
    type: Boolean,
    default: false
  },
  showBackLink: {
    type: Boolean,
    default: false
  },
  showInfoIco: {
    type: Boolean,
    default: false
  },
  listenBackLinkClick: {
    type: Boolean,
    default: false
  },
  tooltipText: String
})

const emit = defineEmits(['infoIcoClick','backLinkClick'])
const router = useRouter()

const titleClass = computed(() => props.titleReverseSize ? 'subtitle' : 'title')
const subtitleClass = computed(() => props.titleReverseSize ? 'title' : 'subtitle')

function backLinkClick(){
  if(props.listenBackLinkClick){
    emit('backLinkClick')
  }else{
    router.go(-1)
  }
}
</script>

<style scoped>
.header_wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
}
.back_link {
  display: flex;
  margin-left: -50px;
  padding-right: 30px;
  cursor: pointer;
}

.btn-info {
  display: flex;
  padding-left: 10px;
  cursor: pointer;
}

.wrapper__title {
  display: flex;
  align-items: center;
  padding: 0;
  width: 100%;
}

.title-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  flex: 1;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 2rem;
  line-height: 120%;
  color: #28224B;
}

.subtitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #5A5A66;
}

.m-title-content {
  display: none;
}

@media screen and (max-width: 478px) {
  .m-title-content {
    display: flex;
    height: 40px;
  }
  .title-content .back_link {
    display: none;
  }
  .m-title-content > .back_link {
    margin-left: -5px;
  }
  .title-content {
    gap: 4px;
  }
  .title {
    font-weight: 600;
    font-size: 1.25rem;
  }

  .subtitle {
    font-size: 0.875rem;
  }
}
</style>