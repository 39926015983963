<template>
  <div v-if="conversations.length > 0" class="chats__wrapper">
    <div class="chats__wrapper_">
      <div class="chats_content">
        <div v-if="title" class="chats-title">
          {{ title }}
          <span>({{ conversationsCount }})</span>
        </div>
        <div class="chat-list">
          <ConversationItem
            v-for="conversation in conversations"
            :image="conversation.image"
            :title="conversation.title"
            :messageDate="conversation.last_message.created_at"
            :message="conversation.last_message.body"
            :newMessagesCount="conversation.new_messages_count"
            :key="conversation.plan_id"
            @click="$emit('conversationClick', conversation)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ConversationItem from './ConversationItem.vue';
import { computed } from 'vue';

const props = defineProps({
  title: String,
  conversations: {
    type: Array,
    required: true
  }
})

defineEmits(['conversationClick'])

const conversationsCount = computed(() => props.conversations.length)

</script>

<style scoped>
.chats__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 48px;
  width: 100%;
}
.chats__wrapper_ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
}
.chats_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
}
.chats-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  font-weight: 600;
  font-size: 1.25rem;
  color: #28224B;
}
.chats-title span{
  font-weight: 400;
}
.chat-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
}

@media screen and (max-width: 478px) {
  .chats-title {
    font-size: 1rem;
  }
}
</style>