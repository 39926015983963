<template>
  <div class="form-group">
    <label
      v-if="labelName"
      :for=name
    >
      {{ labelName }}
    </label>
    <div class="form-control">
      <input
        :value="modelValue"
        :name=name
        :type="state.type"
        :class="{ 'is-invalid': errorMessage }"
        :placeholder=placeholder
        :required=required
        @input="$emit('update:modelValue', $event.target.value)"
        @change="$emit('change', $event.target.value)"
        @focus="$emit('focus', $event.target.value)"
        @blur="$emit('blur', $event.target.value)"
        @keyup="$emit('keyup', $event.target.value)"
      />
      <div
        class="btn-eye"
        :style="state.styleBtnEye"
        @click="toggleEye"
      ></div>
    </div>
    <div class="invalid-feedback" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import { reactive, computed } from 'vue';

defineProps({
  modelValue: String,
  labelName: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    required: true
  },
  required: {
    type: Boolean,
    default: false
  },
  placeholder: String,
  errorMessage: String
})

defineEmits([
  'update:modelValue',
  'change',
  'blur',
  'focus',
  'keyup'
])

const state = reactive({
  isShowing: false,
  styleOpenedEye: {
    backgroundImage: `url(${require('../../assets/img/icons/eye.svg')})`
  },
  styleClosedEye: {
    backgroundImage: `url(${require('../../assets/img/icons/eye_closed.svg')})`,
    backgroundSize: '20px 20px'
  },
  type: computed(() => state.isShowing ? 'text' : 'password'),
  styleBtnEye: computed(() => state.isShowing ? state.styleOpenedEye : state.styleClosedEye )
})

const toggleEye = () => {
  state.isShowing = !state.isShowing
}

</script>

<style scoped>
.form-control_wrapper {
  display: flex;

  width: 100%;
}

.form-control input {
  display: flex;
  flex: 1;
  color: inherit;
  outline: none;
  box-shadow: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.5rem;
}

.btn-eye {
  margin-left: auto;
  display: flex;
  overflow: visible;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

@media screen and (max-width: 478px) {
  .form-control input {
    line-height: 1.31rem;
  }
}
</style>