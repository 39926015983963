<template>
  <div class="home-bg">
    <div class="home_wrapper">
      <div class="home-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.home_wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 64px;
  gap: 48px;

  width: 668px;
  margin: 0 auto;

  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 5px rgba(0, 0, 0, 0.04), 0px 1px 10px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

@media screen and (max-width: 478px) {
  .home-content {
    width: 100%;
    height: 100vh;
    box-shadow: none;
    border-radius: 0px;
    padding: 32px 16px;
    gap: 32px;
  }
}
</style>
