<template>
  <div
    class="skill-container"
    :style="containerStyle"
  >
    <svg v-bind="svgAttr">
      <circle v-bind="circleBgAttr" />
      <circle v-bind="circleFgAttr" />
    </svg>
    <span
      v-if="showCounter"
      class="center-counter"
    >{{ currentCounter }}{{ counterSuffix }}</span>
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch } from 'vue';

const props = defineProps({
  size: {
    type: Number,
    default: 60
  },
  bgColor: {
    type: String,
    default: "#E2E6F9"
  },
  fillColor: {
    type: String,
    default: "#229F7D"
  },
  counter: {
    type: Number,
    default: 0
  },
  startFrom: {
    type: Number,
    default: 0
  },
  maxCounter: {
    type: Number,
    default: 100
  },
  borderWidth: {
    type: Number,
    default: 4
  },
  borderBgWidth: {
    type: Number,
    default: 6
  },  
  transition: {
    type: Number,
    default: 1000
  },
  showCounter: {
    type: Boolean,
    default: true
  },
  counterSuffix: {
    type: String,
    default: "%"
  },
  pause: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['finished'])

const currentCounter = ref(props.startFrom);

const containerStyle = reactive({
  height: props.size + 'px',
  width: props.size + 'px',
})

const svgAttr = {
  style: {
    overflow: 'visible',
    transform: 'rotate(-90deg)'
  },
  xmlns: 'http://www.w3.org/2000/svg',
  viewBox: `${props.size / 2} ${props.size / 2} ${props.size} ${props.size}`
};

const circleRadiusBg = () => {
  let value = (props.size - props.borderBgWidth) * 0.5;
  if (props.borderWidth > props.borderBgWidth) {
    value -= (props.borderWidth - props.borderBgWidth) * 0.5;
  }
  return value;
};

const circleRadiusFg = () => {
  let value = (props.size - props.borderWidth) * 0.5;
  if (props.borderBgWidth > props.borderWidth) {
    value -= (props.borderBgWidth - props.borderWidth) * 0.5;
  }
  return value;
};

const initOffset = () => {
  if(props.startFrom === 0){
    return 2 * Math.PI * circleRadiusFg()
  }
  return 0
}

const circleBgAttr = {
  cx: props.size,
  cy: props.size,
  r: circleRadiusBg(),
  stroke: props.bgColor,
  "stroke-width": props.borderBgWidth,
  fill: "none",
};

const circuit = 2 * Math.PI * circleRadiusFg();
const offset = ref(initOffset());
let timer = 0

const circleFgAttr = computed(() => ({
  cx: props.size,
  cy: props.size,
  r: circleRadiusFg(),
  fill: 'none',
  'stroke-linecap': 'round',
  'stroke-width': props.borderWidth,
  'stroke-dashoffset': offset.value,
  'stroke-dasharray': circuit,
  stroke: props.fillColor,
  ...(props.transition && {
    style: { transition: `stroke-dashoffset ${props.transition}ms` }
  })
}));

function updateCounter() {
  if(props.pause) return
  
  const maxCounterValue = props.maxCounter > props.counter ? props.maxCounter : props.counter
  const circuit = 2 * Math.PI * circleRadiusFg();
  offset.value = circuit - (circuit * props.counter) / maxCounterValue;
  const newCounter = Math.round(maxCounterValue - (maxCounterValue / circuit) * offset.value);
  animateCounterText(newCounter);
}

function animateCounterText(to) {
  const step = to - currentCounter.value;

  if (step) {
    const delay = props.transition / Math.abs(step);
    timer = window.setInterval(() => {
      if(props.pause) return

      if (step > 0) {
        currentCounter.value += 1;
        if (currentCounter.value >= to) {
          window.clearInterval(timer);
          emit('finished')
        }
      } else {
        currentCounter.value -= 1;
        if (currentCounter.value <= to) {
          window.clearInterval(timer);
          emit('finished')
        }
      }
    }, delay);
  }
}

 watch(
   () => props.counter,
   () => {
     updateCounter();
   }
 );
</script>

<style scoped>

.skill-container {
  position: relative;
  display: flex;
}

.skill-container .center-counter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
}
</style>