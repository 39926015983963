<template>
  <div class="chat-item">
    <div class="chat-item_icon_wrapper">
      <div class="chat-item_icon">
        <img
          v-if="image"
          :src="image"
          :alt="title"
        />
      </div>
    </div>
    <div class="chat-item_info">
      <div class="chat-name_time">
        <div class="container">
          <div class="chat-name elipsis">{{ title }}</div>
        </div>
        <div class="chat-time">{{ dateText }}</div>
      </div>

      <div class="chat-title_wrapper">
        <div class="container">
          <div class="chat-message multiline-elipsis">{{ message }}</div>
        </div>
        <CircleCounter
          v-if="showCounter"
          :counter=newMessagesCount
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import CircleCounter from '../UI/CircleCounter.vue';

const props = defineProps({
  image: String,
  title: String,
  messageDate: String,
  message: String,
  newMessagesCount: Number
})

const showCounter = computed(() => props.newMessagesCount > 0)
const dateText = computed(() => props.messageDate ? getDateText(props.messageDate) : null)

function dateDiff(firstDate, secondDate){
  return Math.round((firstDate - secondDate) / (1000 * 60 * 60 * 24));
}

function getDateDotedStr(mDate){
  const year = mDate.getFullYear();
  let month = mDate.getMonth() + 1;
  let day = mDate.getDate();

  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  return day + '.' + month + '.' + year;
}

function getDateText(dateStr){
  const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  const currentDate = new Date()
  const messageDate = new Date(dateStr)
  const dayDiff = dateDiff(currentDate, messageDate)

  if(dayDiff == 0){
    // let hours = messageDate.getUTCHours()
    // const minutes = messageDate.getUTCMinutes()
    // let type = hours > 12 ? "PM" : "AM";
    // if( hours > 12 ){
    //   hours -= 12;
    // }    

    // return hours + ':' + minutes + ' ' + type

    return messageDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })    
  }

  if(dayDiff == 1){
    return 'Yesterday'
  }

  if(dayDiff>1 && dayDiff<8){
    return weekday[messageDate.getDay()];
  }

  return messageDate.toLocaleDateString()
  // return getDateDotedStr(messageDate)
}


</script>

<style scoped>
.container {
  overflow: hidden;
  display: grid;
}

.multiline-elipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}

.elipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-item {
  display: flex;
  padding: 16px;
  gap: 16px;
  height: 90px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 18px -8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
}

.chat-item_icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-item_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: #D9D9D9;
  border-radius: 46px;
}

.chat-item_icon img {
  width: 48px;
  height: 48px;
  border-radius: 46px;
  object-fit: cover;
}

.chat-item_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  gap: 4px;
  align-self: stretch;
  flex-grow: 0;
}

.chat-name_time {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.chat-name {
  padding: 0px;
  font-weight: 500;
  color: #28224B;
}

.chat-time{
  color: #49495B;
  font-size: 0.875rem;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.chat-title_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.chat-message {
  padding: 0px;
  color: #49495B;
  font-size: 0.875rem;
}

.chat-counter_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 8px;
  width: 20px;
  height: 20px;
  background: #229F7D;
  box-shadow: 0px 4px 8px rgba(82, 97, 115, 0.2);
  border-radius: 53px;

  flex: none;
  order: 1;
  flex-grow: 0;
}
.chat-counter {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

@media screen and (max-width: 478px) {
  .chat-name {
    font-size: 0.875rem;
  }

  .chat-message, .chat-time {
    font-size: 0.75rem;
  }
}
</style>


