import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView'
import { useStore } from '../store/useStore'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      forGuestOnly: true
    }
  },
  {
    path: '/signup',
    name: 'signup',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignupView.vue'),
    meta: {
      forGuestOnly: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/LogoutView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/contact-us',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "contactUs" */ '../views/ContactUsView.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/ForgotPasswordView.vue'),
    meta: {
      forGuestOnly: true
    }
  },
  {
    path: '/restore-password',
    name: 'restorePassword',
    component: () => import(/* webpackChunkName: "restorePassword" */ '../views/RestorePasswordView.vue'),
    meta: {
      forGuestOnly: true
    }
  },
  {
    path: '/treatment/:treatment_slug/medical-details/:partner/:partner_gp?',
    name: 'medicalDetails',
    component: () => import(/* webpackChunkName: "medicalDetails" */ '../views/MedicalDetailsView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/referral-code',
    name: 'referralCode',
    component: () => import(/* webpackChunkName: "referralCode" */ '../views/ReferralCodeView.vue')
  },

  {
    path: '/request-plan/:partner_slug/:partner_gp',
    name: 'requestPartnerPlan',
    component: () => import('../views/search/RequestPartnerPlan.vue')
  },
  
  {
    path: '/explore-plans',
    name: 'explorePlans',
    component: () => import(/* webpackChunkName: "explorePlans" */ '../views/search/ExplorePlansView.vue')
  },
  {
    path: '/browse-plans',
    name: 'browsePlans',
    component: () => import(/* webpackChunkName: "browsePlans" */ '../views/search/BrowsePlansView.vue'),
    // beforeEnter: (to, from) => {
    // }
  },
  {
    path: '/search-plans',
    name: 'searchPlans',
    component: () => import(/* webpackChunkName: "searchPlans" */ '../views/search/SearchPlansView.vue'),
  },
  {
    path: '/browse-plans/:category_slug',
    name: 'treatmentGroups',
    component: () => import(/* webpackChunkName: "treatmentGroups" */ '../views/search/TreatmentGroupsView.vue')
//    props: true
  },
  {
    path: '/browse-plans/:category_slug/:group_slug',
    name: 'treatments',
    component: () => import(/* webpackChunkName: "treatments" */ '../views/search/TreatmentsView.vue')
  },
  {
    path: '/treatment/:treatment_slug/:partner/:partner_gp?',
    name: 'treatment',
    component: () => import(/* webpackChunkName: "treatment" */ '../views/search/TreatmentView.vue')
  },
  {
    path: '/plan/:id',
    name: 'plan',
    component: () => import('../views/patient/PlanView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/plan/:plan_id/medical-details',
    name: 'planMedicalDetails',
    component: () => import('../views/MedicalDetailsView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/plan/:plan_id/week/:week/day/:week_day',
    name: 'session',
    component: () => import('../views/patient/SessionView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/plan/:plan_id/week/:week/day/:week_day/training',
    name: 'training',
    component: () => import('../views/patient/TrainingView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/plan/:plan_id/week/:week/session_feedback',
    name: 'sessionFeedback',
    component: () => import('../views/patient/SessionFeedbackView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/plan/:plan_id/feedback',
    name: 'planFeedback',
    component: () => import('../views/patient/PlanFeedbackView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/plan/:plan_id/messages',
    name: 'planMessages',
    component: () => import('../views/patient/PlanConversationView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/plan/:plan_id/psfs',
    name: 'planPsfs',
    component: () => import('../views/patient/PlanPsfsView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/patient/ProfileView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/edit-profile',
    name: 'editProfile',
    component: () => import('../views/patient/ProfileEditView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('../views/patient/ConversationsView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/patient/SettingsView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import('../views/patient/ChangePasswordView.vue'),
    meta: {
      requiresAuth: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  const store = useStore()
  store.fromRoute = from

  if(to.name=='requestPartnerPlan'){
    let partnerSlug = ''
    let partnerGP = ''
    if(to.params.partner_slug && to.params.partner_gp){
      partnerSlug = to.params.partner_slug
      partnerGP = to.params.partner_gp
    }
    store.initPartner(partnerSlug, partnerGP)
    
    return { name: 'explorePlans'}
  }

  // if(!store.accessGranted && to.name!='home'){
  //   store.redirectUrl = to.fullPath
  //   return { name: 'home' }
  // }

  if (!store.isAuth && localStorage.getItem('authToken')){
    store.authToken = localStorage.getItem('authToken')
  }

  if(to.meta.requiresAuth && !store.isAuth){
    return { name: 'login', query: { to: to.path }}
  }

  if(to.meta.forGuestOnly && store.isAuth){
    return { name: 'home' }
  }

  if(store.isAuth && store.newPasswordRequired && to.name!='home' && to.name!='logout' && to.name!='resetPassword'){
    return { name: 'resetPassword' }
  }

})

export default router
