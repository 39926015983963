<template>
  <router-view/>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStore } from "./store/useStore";
import { useAuthStore } from "./store/useAuthStore"

if(process.env.VUE_APP_BUGHEARD_LOAD === 'true'){
  includeBH()
}

if(process.env.VUE_APP_IUBENDA_COOKIE_LOAD === 'true'){
  includeIubendaCookiePlugin()
}

const store = useStore()
const authStore = useAuthStore()

//include BugHeard
function includeBH(){
  const bhScript = document.createElement("script")
  bhScript.setAttribute(
    'src',
    'https://www.bugherd.com/sidebarv2.js?apikey=fw8l6o3tkhp7hzna9xumna'
  )
  bhScript.setAttribute(
    'async',
    'true'
  )
  document.head.appendChild(bhScript)
}

// include Iubenda Cookie plugin
function includeIubendaCookiePlugin(){
  const iubScript1 = document.createElement("script")
  iubScript1.innerHTML='var _iub = _iub || []; _iub.csConfiguration = {"cookiePolicyInOtherWindow":true,"enableRemoteConsent":true,"lang":"en","perPurposeConsent":true,"siteId":2173673,"cookiePolicyId":12499304,"banner":{"acceptButtonCaptionColor":"white","acceptButtonColor":"#229f7d","acceptButtonDisplay":true,"backgroundColor":"white","closeButtonDisplay":false,"customizeButtonCaptionColor":"#4d4d4d","customizeButtonColor":"#dadada","customizeButtonDisplay":true,"position":"float-bottom-left","rejectButtonCaptionColor":"#4d4d4d","rejectButtonColor":"#dadada","rejectButtonDisplay":true,"textColor":"black"}};'
  document.head.appendChild(iubScript1)

  const iubScript2 = document.createElement("script")
  iubScript2.setAttribute('src', '//cs.iubenda.com/sync/2173673.js')
  iubScript2.setAttribute('async','true')
  document.head.appendChild(iubScript2)

  const iubScript3 = document.createElement("script")
  iubScript3.setAttribute('src', '//cdn.iubenda.com/cs/iubenda_cs.js')
  iubScript3.setAttribute('charset','UTF-8')
  iubScript3.setAttribute('async','true')
  document.head.appendChild(iubScript3)
}

onMounted(() => {
  if (localStorage.getItem('authToken')){
    authStore.getUserData()
  }
})
</script>

<style>
/* Google Font Link */
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@200;300;400;500;600;700&display=swap');

*, ::after, ::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3); 
}
 
::-webkit-scrollbar-thumb {
  background: rgba(29, 29, 29, 0.3);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(29, 29, 29, 0.2);
}

body {
  font-family: "Lexend", sans-serif;
  position: relative;
  min-height: 100vh;
  width: 100%;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: none
}

input[type="checkbox"] {
  accent-color: #49495B;
}

h1,h2,h3,h4,h5,h6{
  color: #28224B;
}
h1{
  font-weight: 600;
  font-size: 2rem;
  line-height: 120%;
}

a {
  color: #229F7D;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
}

button {
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 24px;
  width: 100%;
}

#app {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #5A5A66;
  background: #FAFAFD;
}

.home-bg {
  background: linear-gradient(to right, rgba(40, 34, 75, 0.8), rgba(40, 34, 75, 0.8)), url('assets/img/bg.jpg') no-repeat;
  background-position: center;
  background-size: cover;
}

.main-section {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  top: 0;
  left: 376px;
  width: calc(100% - 376px);
  padding: 90px 80px; 
  transition: all 0.5s ease;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}

.w-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}

.w-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    width: 100%;
}

.w-controls {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 100%;
}
.form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 0.5rem;
    width: 100%;
}

.form-group label {
    font-weight: 500;
    color: #28224B;
}

.form-control {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.875rem 1rem;
  gap: 0.625rem;

  color: #5A5A66;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E1E4;
  transition: border-color .2s cubic-bezier(0.645, 0.045, 0.355, 1);
  /* -webkit-transition: 0.5s;
  transition: 0.5s; */
  border-radius: 0.5rem;
  line-height: 1.5rem;

  outline: none;
  box-shadow: none;
  -moz-appearance: none;
  appearance: none;
  
}

.form-control:focus {
  border-color: #aaaeb7;
  outline: none
}

.form-control:hover {
  border-color: #aaaeb7;
}

.form-control.is-invalid {
  border: 1px solid #BB4D4D;
}

.form-group .invalid-feedback, .summary-error {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #BB4D4D
}

.dp__theme_light {
  --dp-border-radius: 0.5rem;
  --dp-input-padding: 0.875rem 1rem;
  --dp-font-family: "Lexend", sans-serif;
  --dp-text-color: #5A5A66;
}

.sub-block_text ul,
.sub-block_text ol {
  padding-left: 30px;
}

.rich-text h1{
  font-weight: 600;
  font-size: 1.25rem;
}
.rich-text h2{
  font-weight: 500;
  font-size: 1.2rem;
}
.rich-text h3{
  font-weight: 500;
  font-size: 1.1rem;
}
.rich-text h4{
  font-weight: 400;
  font-size: 1rem;
}
.rich-text h5{
  font-weight: 400;
  font-size: 0.875rem;
}
.rich-text h6{
  font-weight: 400;
  font-size: 0.7rem;
}

@media screen and (max-width: 890px) {

  .main-section {
    left: 76px;
    width: calc(100% - 76px);
    padding: 30px 20px; 
  }
}

@media screen and (max-width: 478px) {
  #app {
    font-size: 0.875rem;
  }
  .main-section {
    left: 46px;
    width: calc(100% - 46px);
    padding: 30px 20px; 
  }
  .main-content {
    gap: 32px;
  }
  h1{
    font-size: 1.25rem;
  }
  .form-control {
    font-size: 0.875rem;
    line-height: 1.31rem;
  }
  .dp__theme_light {
    --dp-font-size: 0.875rem;
  }
  .rich-text h1{
    font-weight: 600;
    font-size: 1rem;
  }
  .rich-text h2{
    font-weight: 500;
    font-size: 0.95rem;
  }
  .rich-text h3{
    font-weight: 500;
    font-size: 0.9rem;
  }
  .rich-text h4{
    font-weight: 400;
    font-size: 0.875rem;
  }
  .rich-text h5{
    font-weight: 400;
    font-size: 0.75rem;
  }
  .rich-text h6{
    font-weight: 400;
    font-size: 0.625rem;
  }
}
</style>
