<template>
  <CenteredCard>

    <div class="w-title">
      <h1>Login</h1>
      <p>
        New here?
        <router-link to="/signup">Create an account</router-link>
      </p>
    </div>

    <div class="w-content">
      <form @submit.prevent="submitForm">
        <TextInput
          v-model.trim="form.email"
          label-name="Email Address"
          type="email"
          name="email"
          :errorMessage="formErrors.email"
          required
        />

        <PasswordInput
          v-model.trim="form.password"
          label-name="Password"
          name="password"
          :errorMessage="formErrors.password"
          required
        />

        <div
          class="summary-error"
          v-if="store.summaryError"
        >
          {{ store.summaryError }}
        </div>

        <GreenButton
          type="submit"
          :disabled="state.submitDisabled"
        >Login</GreenButton>
        <router-link to="/forgot-password">Forgot your password?</router-link>
      </form>

    </div>

  </CenteredCard>
</template>

<script setup>
import GreenButton from "../components/UI/GreenButton";
import CenteredCard from "../components/UI/CenteredCard";
import TextInput from "../components/UI/TextInput.vue";
import PasswordInput from "../components/UI/PasswordInput.vue";
import { reactive, computed, onMounted } from "vue";
import { useStore } from "../store/useStore";
import { useAuthStore } from "../store/useAuthStore";
import { useRouter, useRoute } from "vue-router";

const store = useStore()
const router = useRouter()
const route = useRoute()

const state = reactive({
  submitDisabled: false,
})

const form = reactive({
  email: '',
  password: '',
})

const formErrors = reactive({
  email: computed(() => store.errors.email ? store.errors.email[0] : '' ),
  password: computed(() => store.errors.password ? store.errors.password[0] : '' ),
})

const submitForm = async () => {
  state.submitDisabled = true
  const authStore = useAuthStore()
  const result = await authStore.sendLoginRequest(form.email, form.password)

  if(store.isAuth){
    if(store.redirectAfterAuthorizeUrl != ''){
      let goTo = store.redirectAfterAuthorizeUrl
      store.redirectAfterAuthorizeUrl = ''
      router.push(goTo)
    } else {
      router.push({ name: 'home'})
    }
  }

  state.submitDisabled = false
}

onMounted(() => {
  store.setErrors({})

  if(route.query.to){
    store.redirectAfterAuthorizeUrl = route.query.to
  }

})

</script>
