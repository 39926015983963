import { defineStore } from "pinia";
import { ref } from "vue";
import { useStore } from "./useStore";
import axios from "axios";

export const useAuthStore = defineStore('auth', () => {
  const user = ref()

  async function sendLoginRequest(username, password) {
    const store = useStore()
    store.setErrors({})
    store.summaryError = null

    try {
      const response = await axios
        .post(process.env.VUE_APP_API_AUTH_URL + "oauth/token", {
          grant_type: 'password',
          username: username,
          password: password,
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET
        });

      store.authToken = response.data.access_token
      localStorage.setItem("authToken", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);

      getUserData()
      
    } catch (error) {
      console.log(error);
    }
  }

  async function sendSignupRequest(firstName, lastName, email, phone, birthDate, password) {
    const store = useStore()
    store.setErrors({})
    store.summaryError = null

    try {
      const response = await axios
        .post(process.env.VUE_APP_API_URL + 'register', {
          name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          birth_date: birthDate,
          password: password
        })
  
      const loginResult = await sendLoginRequest(email, password)
      
    } catch (error) {
      console.log(error);
    }
  }

  function getUserData() {
    const store = useStore()
    if (user.value) return

    axios
      .get(process.env.VUE_APP_API_URL + "user/profile")
      .then(response => {
        user.value = response.data.data
      })
      .catch(() => {
        localStorage.removeItem("authToken")
        localStorage.removeItem("refreshToken")
        user.value = null
      })
  }

  async function storeUserData(firstName, lastName, email, phone, nhsNumber, birthDate, gender, weight, height, photo) {
    const store = useStore()
    store.setErrors({})
    store.summaryError = null

    try {
      const formData = new FormData()
      formData.append('name', firstName)
      formData.append('email', email)

      if(lastName) formData.append('last_name', lastName)
      if(nhsNumber) formData.append('nhs_number', nhsNumber)
      if(birthDate) formData.append('birth_date', birthDate)
      if(gender) formData.append('gender', gender)
      if(phone) formData.append('phone', phone)
      if(weight) formData.append('weight', weight)
      if(height) formData.append('height', height)

      formData.append('_method', 'PUT')
        
      if(photo) formData.append('photo', photo)

      const response = await axios
        .post(process.env.VUE_APP_API_URL + 'user/profile', formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }        
      )
  
      user.value = response.data.data
      
      return true
    } catch (error) {
      console.log(error);
    }

    return false
  }

  function changeUserNotificationStatus(status){
    if(user && (status === true || status === false)){
      let aUser = user.value
      aUser.settings.is_notifications_enabled = status
      user.value = aUser
    }
  }

  async function resetPassword(oldPassword, newPassword, newPasswordConfirmation) {
    const store = useStore()
    store.setErrors({})
    store.summaryError = null

    try {
      await axios
        .put(process.env.VUE_APP_API_URL + 'user/change-password', {
          old_password: oldPassword,
          new_password: newPassword,
          new_password_confirmation: newPasswordConfirmation
        })
  
      return true
    } catch (error) {
      console.log(error);
    }

    return false
  }

  async function deleteAccount() {
    const store = useStore()

    try {
      await axios.delete(
        process.env.VUE_APP_API_URL + "user/account"
      )
    } catch (error) {
      console.log(error)
    }

    localStorage.removeItem("authToken")
    localStorage.removeItem("refreshToken")
    user.value = null
    store.authToken = null

  }

  return { user, sendLoginRequest, sendSignupRequest, getUserData, storeUserData, changeUserNotificationStatus, resetPassword, deleteAccount }
})